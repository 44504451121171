import type {ActionFunctionArgs, LoaderFunctionArgs} from "@remix-run/node";
import {authenticator} from "~/services/user.auth.server";
import LoginForm from "~/components/ui/login-form";
import {useActionData} from "@remix-run/react";
import {AuthorizationError} from "remix-auth";

export async function action({request}: ActionFunctionArgs) {
    try {
        return await authenticator.authenticate("user-pass", request, {
            successRedirect: "/profile",
            throwOnError: true
        });
    } catch (error) {
        if (error instanceof AuthorizationError) {
            return {errorMessage: error};
        }
        throw error;
    }
}

export async function loader({request}: LoaderFunctionArgs) {
    return await authenticator.isAuthenticated(request, {
        successRedirect: "/profile",
    });
}

export default function UserLogin() {
    const data = useActionData();
    return (
        <LoginForm description="Личный кабинет" hasError={data && data.errorMessage} />
    );
}